/* @import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap'); */

@media print {
  @page {
    size: A4;
    margin: 0;
  }
  body {
    margin: 0;
    padding: 0;
  }
  .printable-area {
    page-break-inside: avoid;
    break-inside: avoid;
    -webkit-break-inside: avoid;
    margin: 0;
    padding: 20px;
    box-shadow: none;
    border: none;
  }
  .header_diplome, .footer_diplome {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .diploma-title {
    font-family: 'Great Vibes', cursive;
    font-size: 2.5em;
    color: #000;
    margin: 0;
  }
  /* .kanji-image, .jigoro-image, .code-moral-image img {
    width: 100%;
    height: auto;
  }
  .kanji-image {
    max-width: 100px;
  }
  .jigoro-image {
    max-width: 120px;
  }
  .code-moral-image {
    max-width: 100%;
    height: 400px;
    object-fit: contain;
    margin-bottom: 20px;
  } */
  .signatures {
    margin-top: 40px; /* Ajoute de l'espace au-dessus des signatures */
    text-align: center;
  }
  .footer_diplome {
    margin-top: 40px; /* Ajoute de l'espace au-dessus du footer */
  }
  .footer_diplome img {
    width: 120px; /* Augmente la taille du logo dans le footer */
  }
  .footer_diplome p {
    margin: 0.25em 0;
  }
}
