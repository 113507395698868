@media print {
  body * {
    visibility: hidden;
  }
  .printable-area, .printable-area * {
    visibility: visible;
  }
  .printable-area {
    position: absolute;
    left: 50%; /* Center horizontally */
    top: 0;
    transform: translateX(-50%); /* Center horizontally */
    width: 75%; /* Adjusted to 75% of the page width */
    padding: 0 20px;
  }
  .printable-area img {
    max-height: 150px;
  }
}

.container_print {
  max-width: 1200px;
  width: 90%; /* Ensure the container is responsive */
}

.text-center img {
  max-height: 150px;
}
