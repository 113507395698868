.link-nav2 {
  position: relative;
  overflow: hidden;
  padding-bottom: 0.375rem; /* Ajuste ce padding selon le besoin */
  transform: scaleY(.9);
}

.link-nav2::after {
  content: '' !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 50% !important;
  width: 0% !important;
  height: 3px !important;
  background-color: #F27131 !important; /* Couleur de la barre */
  transition: all 0.3s ease-in-out !important;
}

.link-nav2:hover::after {
  left: 0 !important;
  width: 100% !important;
}

.active2::after {
  left: 0;
  width: 100%;
}

.mega-menu {
  position: absolute;
  width: 100%;
  
  z-index: 20; /* Assurez-vous que le menu est au-dessus des autres contenus */

}

.flex-container {
  display: flex;
  justify-content: space-between; /* Vous utilisez déjà quelque chose de similaire */
  align-items: center;
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne si nécessaire */
}

/* Ajustez les marges et le padding des éléments pour éviter le chevauchement */
.nav-link, .application-button {
  margin-right: 10px; /* Ajustez selon vos besoins */
}

.mega-menu-wrapper {
  position: absolute;
  min-width: 300px;
  right: 20px;
  top: 70px; /* Ajustez cette valeur si nécessaire pour éviter le chevauchement */
  z-index: 20; /* S'assurer qu'il est au-dessus des autres éléments */
}