@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
/* Importation font Montserrat de Google Font*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/*-       A GARDER --------------------------------------------------------------*/
.app-container {
  display: flex;
  height: 100vh;
}

.app-layout {
  display: flex;
}

.main-container {
  transition: margin-left 0.3s ease;
  flex-grow: 1;
}

.main-container.expanded {
  margin-left: 260px; /* Ajuste cette valeur en fonction de la largeur de ta SideNav étendue */
}

.main-container.collapsed {
  margin-left: 80px; /* Ajuste cette valeur en fonction de la largeur de ta SideNav réduite */
}

.content-container {
  transition: margin-left 0.3s ease;
  width: calc(100% - 260px); /* Largeur par défaut quand SideNav est étendue */
  height: 100vh;
}

.content-container.collapsed {
  margin-left: 50px;
  width: calc(100% - 50px); /* Ajuste la largeur quand SideNav est réduite */
}

.content-container.expanded {
  margin-left: 260px;
}

.main-content {
  padding-top: 100px; /* Ajuste selon le besoin */
  overflow-y: auto;
  height: calc(100vh - 100px); /* Ajuste la hauteur pour tenir compte du padding */
}

/* Ajoutez cette règle pour surcharger le style de survol par défaut */
.menu-list a.is-hoverable:hover,
.menu-list a.is-hoverable:hover .icon {
  background-color: #ffd13a !important;
  /* couleur de fond au survol */
  color: black !important;
  /* couleur du texte au survol */
}



/*-- USER LISTING --*/
.userlisting__container{
  position: absolute;
    left: 275px;
    width: 100%;
    display: flex;
    align-items: center;
}

.edit-user-form-container{
  position: absolute;
    left: 275px;
    width: 100%;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
}

/*-  LISTING ADHERENT --*/

.home__container {
  position: absolute;
  left: 275px;
  width: 100%;
  display: flex;
  align-items: center;
}

.styled-table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: rgb(255, 217, 91);
  color: #000;
  text-align: left;
  max-width: 50px;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #DDD;
  text-align: left;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #F3F3F3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 5px solid rgb(255, 217, 91);
}

.btn {
  border: none;
  color: #FFF;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
}

.btn-edit {
  background-color: #008cba;
}

.btn-delete {
  background-color: #f44336;
}

.btn-view {
  background-color: #e7e7e7;
  color: #000;
}

.btn-contact {
  background-color: #e82764;
  margin-bottom: 40px;
}

.btn-prospect {
  background-color: cyan;
  color: #000;
}

.btn-courdessai {
  background-color: violet;
}

.btn-inscris {
  background-color: yellow;
  color: #000;
}

.btn-licencie {
  background-color: green;
}

.btn-licencieext {
  background-color: rgb(0, 90, 0);
}

.btn-ancienslicencies {
  background-color: grey;
}

.btn-licenciesarret {
  background-color: #000;
}

/*-- FICHE ADHERENT -*/

.ficheadherent-container{
  position: absolute;
  left: 275px;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adherent-info-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: rgb(158, 158, 158);
  border-radius: 80px 0 0 80px;
  margin-top: 15px;
  width: 100%;
  margin-bottom: 25px;
}

.ficheadherent-photo{
  max-width: 120px;
  border-radius: 50%;
  margin: 15px;
  border: 8px solid #ffd13a;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.ficheadherent-nom{
  font-size: 35px;
  font-weight: 600;
  line-height: 0px;
}

.ficheadherent-prenom{
  font-size: 25px;
  font-weight: 300;
  line-height: 20px;
}

.row{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 25px ;
}

.row-encart{
  display: inline-flex;
  justify-content: center;
  align-items: start;
  gap: 25px;
}

.bloc-gestionnaire{
  display: flex;
}

.gestionnaire-name{
  background-color: rgb(255, 255, 255);
  padding: 0px 10px;
  border-radius: 10px;
  border: 1px solid #333;
  margin-bottom: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.adherent-encart-container{
  border: 1px solid #333;
}

.title-bloc{
  background-color: #000;
  color: #FFF;
  width: 100%;
  text-align: center;
}

.adherent-encart-container p, .adherent-encart-container li {
  padding: 0 10px;
}

.adherent-encart-container h3{
  width: 100%;
  height: 100%;
}

.belt-image{
  width: 100%;
  max-width: 250px;
  align-self: center;
}


/*-- MESSAGES -*/
.message__container{
  position: relative;
  left: 50px;
}

.competitors__container{
  position: relative;
  left: 50px;
}


/*--  TECHNIQUES --*/
.techniques-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.technique-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  overflow: hidden;
  width: 300px;
  /* Largeur fixe des cartes */
  height: 500px;
  /* Hauteur fixe des cartes */
  display: flex;
  flex-direction: column;
}

.technique-card img {
  width: 100%;
  height: 400px;
  /* Hauteur fixe pour les images */
  object-fit: cover;
  /* Adapte l'image à la taille sans perdre les proportions */
  object-position: center;
  /* Centre l'image */
  min-height: 350px;
}

.technique-info {
  padding: 15px;
  flex-grow: 1;
  /* Permet à cette section de remplir l'espace restant */
}

.technique-info h2,
.technique-info h3 {
  margin: 5px 0;
}

.technique-info h2 {
  font-size: 1.2em;
}

.technique-info h3 {
  font-size: 1.0em;
  color: #666;
}

.technique-ceinture {
  height: 10px;
  /* Hauteur de la barre de couleur */
  width: 100%;
}

.filters {
  margin: 0 auto;
  width: 600px;
  margin-bottom: 20px;
  text-align: center;
}

.filters select {
  margin: 10px 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.technique-card .card-image {
  position: relative;
}

.heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.5);
  /* Noir semi-transparent */
  cursor: pointer;
}

.heart-icon.liked {
  color: #ffeb3b;
  /* Jaune */
  text-shadow: 0 0 10px #ffc107;
  /* Ombre légère pour le style "liké" */
  filter: drop-shadow(0px 0px 2px rgba(0,0,0,.6));
}

.appelcours__container{
  width: 80%;
  left: 50px;
  position: relative;
}

.checkbox-appel{
  transform: scale(2.5);
}


/*--  ListEvents --*/
.list-events-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
}

.list-events-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  overflow: hidden;
  width: 400px;
  /* Largeur fixe des cartes */
  height: 400px;
  /* Hauteur fixe des cartes */
  display: inline-block;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.list-events-img {
  width: 100%;
  height: 250px;
  /* Hauteur fixe pour les images */
  object-fit: cover;
  /* Adapte l'image à la taille sans perdre les proportions */
  object-position: center;
}

.list-events-info {
  padding: 15px;
  flex-grow: 1;
  /* Permet à cette section de remplir l'espace restant */
}

.list-events-info h2,
.list-events-info h3 {
  margin: 5px 0;
}

.list-events-info h2 {
  font-size: 1.2em;
}

.technique-info h3 {
  font-size: 1.0em;
  color: #666;
}

.technique-ceinture {
  height: 10px;
  /* Hauteur de la barre de couleur */
  width: 100%;
}

.filters {
  margin: 0 auto;
  width: 600px;
  margin-bottom: 20px;
  text-align: center;
}

.filters select {
  margin: 10px 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.technique-card .card-image {
  position: relative;
}

.heart-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 35px;
  color: rgba(0, 0, 0, 0.5);
  /* Noir semi-transparent */
  cursor: pointer;
}

.heart-icon.liked {
  color: #ffeb3b;
  /* Jaune */
  text-shadow: 0 0 10px #ffc107;
  /* Ombre légère pour le style "liké" */
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, .6));
}

.role-administrateur {
    border: none;
      color: #FFF;
      padding: 5px 8px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      border-radius: 5px;
      font-size: 10px;
      margin: 4px 2px;
      cursor: pointer;
      background-color: red;
}

.role-utilisateur {
      border: none;
        color: #FFF;
        padding: 5px 8px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border-radius: 5px;
        font-size: 10px;
        margin: 4px 2px;
        cursor: pointer;
        background-color: blue;
}

.role-enseignant {
      border: none;
        color: #FFF;
        padding: 2px 4px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        border-radius: 5px;
        font-size: 10px;
        margin: 4px 2px;
        cursor: pointer;
        background-color: green;
}