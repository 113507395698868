.sticky-col {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 100;
  width: 150px;
  z-index: 10;
}

.table-scrollable {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
}

.table {
  width: 100%;
  min-width: 1280px; /* Assurez-vous que la largeur minimale est suffisante pour tous les contenus */
}
